<template>  
  <Dialog :common-dialog="qtyQialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          Update Door Type - {{qtyObject.product}} ({{totalQty}}/{{qtyObject.quantity}})
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            color="cyan white--text"
            v-on:click="saveQty();"
            >Save</v-btn
          >
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            v-on:click="$emit('close', true)"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="custom-grey-border">
        <table width="100%" style="table-layout: fixed">
          <thead>
            <tr>
              <th class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4" width="65%">Size <span style="text-transform: none;">(width X height)</span></th>
              <th class="text-center font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4" width="35%">Serial Number</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in qtyArray"
              :key="`door-type-qty-row-${index}`"
              class="alternate-listing-row custom-border-top"
            >
              <td>
                <v-text-field
                  dense
                  filled
                  :ref="`field${index}Ref`"
                  placeholder="Enter Size (width X height)"
                  v-model="row.size"
                  solo
                  flat
                  readonly
                  color="cyan"
                />
              </td>
              <td>
                <v-text-field
                  dense
                  filled
                  placeholder="Enter Serial Number"
                  solo
                  flat
                  v-model="row.serial_no"
                  color="cyan"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { toSafeNumber } from "@/core/lib/math.lib";
import CommonMixin from "@/core/plugins/common-mixin.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
import { cloneDeep } from "lodash";

export default {
  name: "line-item-job-door-type",
  props: {
    qtyQialog: {
      type: Boolean,
      default: false,
    },
    qtyArray: {
      type: Array,
      default() {
        return [];
      },
    },
    qtyObject: {
      type: Object,
      default() {
        return {};
      },
    },
    defObject: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  mixins: [CommonMixin],
  methods: {
    saveQty() {
      ClearEventBus.$emit("clear:error");

      if (toSafeNumber(this.totalQty) != toSafeNumber(this.qtyObject.quantity)) {
        ErrorEventBus.$emit("update:error", `Please Enter Serial Number for All Size`);
        return false;
      }

      this.$emit('save', cloneDeep(this.qtyArray));
    },
  },
  components: { Dialog },
  computed: {
    totalQty() {
      return this.qtyArray.filter(obj => obj.serial_no !== null && obj.serial_no.trim() !== "").length;
    },
    dialogWidth() {
      return toSafeNumber((document.body.clientWidth / 100) * 60);
    },
  }

}
</script>