<template>
  <Dialog :common-dialog="qtyQialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          Update Door Type - {{ qtyObject.product }} ({{ totalQty }}/
          <template v-if="qtyObject.quantity">
            {{ qtyObject.quantity }}
          </template>
          <template v-else> 0 </template>
          )
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            :disabled="disabledQty"
            color="cyan white--text"
            v-on:click="saveQty()"
            >Save</v-btn
          >
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            v-on:click="$emit('close', true)"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="custom-grey-border">
        <table width="100%" style="table-layout: fixed">
          <thead>
            <tr>
              <th
                class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="49%"
              >
                Size <span style="text-transform: none">(width X height)</span>
              </th>
              <th
                v-if="quoteType == 2"
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="16%"
              >
                Serial Number
              </th>
              <th
                v-else
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="16%"
              >
                Quantity
              </th>
              <th
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="16%"
              >
                Unit Cost
              </th>
              <th
                v-if="quoteType == 1"
                class="text-left font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="16%"
              >
                Total Cost
              </th>
              <th
                class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                width="5%"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in qtyArray"
              :key="`door-type-qty-row-${index}`"
              class="alternate-listing-row custom-border-top"
            >
              <td>
                <v-text-field
                  dense
                  filled
                  :ref="`field${index}Ref`"
                  placeholder="Enter Size (width X height)"
                  v-model="row.size"
                  solo
                  flat
                  :readonly="quoteType == 2"
                  color="cyan"
                />
              </td>
              <td v-if="quoteType == 2">
                <v-text-field
                  dense
                  filled
                  placeholder="Enter Serial Number"
                  solo
                  flat
                  readonly
                  v-model="row.serial_no"
                  color="cyan"
                />
              </td>
              <td v-else>
                <v-text-field
                  dense
                  filled
                  placeholder="Enter Quantity"
                  solo
                  flat
                  v-model="row.qty"
                  color="cyan"
                  :maxlength="6"
                  type="number"
                  v-on:keyup="calculateTotal(index)"
                  v-on:keypress="limitDecimal($event, row.qty)"
                />
              </td>
              <td>
                <v-text-field
                  dense
                  filled
                  placeholder="Enter Unit Cost"
                  solo
                  flat
                  v-model="row.price"
                  color="cyan"
                  :maxlength="6"
                  type="number"
                  v-on:keyup="calculateTotal(index)"
                  v-on:keydown.tab="pushQtyRow(index)"
                  v-on:keypress="limitDecimal($event, row.price)"
                />
              </td>
              <td v-if="quoteType == 1">
                <v-text-field
                  dense
                  filled
                  placeholder="Total Cost"
                  solo
                  flat
                  v-model="row.total"
                  color="cyan"
                  type="number"
                  readonly
                />
              </td>
              <td align="right">
                <v-btn
                  class="custom-bold-button"
                  depressed
                  icon
                  v-on:click="deleteQtyRow(index)"
                  color="red lighten-1 white--text"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-slot:action>
      <div v-if="quoteType == 1">
        <v-btn
          style="height: 28px !important"
          class="custom-bold-button"
          depressed
          :disabled="disabledQty"
          v-on:click="addQtyRow()"
          color="cyan white--text"
          >+ Add Row</v-btn
        >
      </div>
    </template>
  </Dialog>
</template>

<script>
import { toSafeNumber, safeAdd, safeMultiply } from "@/core/lib/math.lib";
import CommonMixin from "@/core/plugins/common-mixin.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
import { cloneDeep, template } from "lodash";

export default {
  name: "line-item-door-type",
  props: {
    qtyQialog: {
      type: Boolean,
      default: false,
    },
    qtyArray: {
      type: Array,
      default() {
        return [];
      },
    },
    qtyObject: {
      type: Object,
      default() {
        return {};
      },
    },
    defObject: {
      type: Object,
      default() {
        return {};
      },
    },
    quoteType: {
      type: Number,
      default: 0,
    },
  },
  mixins: [CommonMixin],
  methods: {
    pushQtyRow(index) {
      const nextIndex = index + 1;
      if (nextIndex >= this.qtyArray.length) {
        this.addQtyRow();
      }
    },
    addQtyRow() {
      if (this.disabledQty) {
        return false;
      }

      const def_object = cloneDeep(this.defObject);
      this.qtyArray.push(def_object);
    },
    saveQty() {
      ClearEventBus.$emit("clear:error");

      if (this.blankSizeIndex > -1) {
        this.$refs[`field${this.blankSizeIndex}Ref`][0].$refs["input"].focus();
        ErrorEventBus.$emit("update:error", "Please Enter Size");
        return false;
      }

      if (
        toSafeNumber(this.totalQty) != toSafeNumber(this.qtyObject.quantity)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          `Sum of quantity must be ${this.qtyObject.quantity}`
        );
        return false;
      }

      this.$emit("save", cloneDeep(this.qtyArray));
    },
    calculateTotal(index) {
      this.qtyArray[index].total = safeMultiply(
        this.qtyArray[index].price,
        this.qtyArray[index].qty
      );
    },
    deleteQtyRow(index) {
      this.qtyArray.splice(index, 1);
      if (this.qtyArray.length < 1) {
        this.addQtyRow();
      }
    },
  },
  components: {
    Dialog,
  },
  computed: {
    blankSizeIndex() {
      return this.qtyArray.findIndex(
        (item) => item.size == null || item.size == ""
      );
    },
    totalQty() {
      return this.qtyArray.reduce((acc, row) => safeAdd(acc, row.qty), 0);
    },
    disabledQty() {
      return (
        toSafeNumber(this.totalQty) > toSafeNumber(this.qtyObject.quantity)
      );
    },
    dialogWidth() {
      return toSafeNumber((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>